'use client';
import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 32px;
  border: 2px solid
    ${props =>
      props.checked ? props.theme.colors.secondary.egg : 'transparent'};
  display: inline-flex;
  flex-direction: column;
  user-select: none;
  overflow: hidden; /* prevent overflow on rounded corners */
  position: relative; /* allow absolute positioning of child elements */
  width: 344px;
  height: 182px;
  background: ${({ theme, unavailable }) =>
    unavailable
      ? theme.colors.background.disabled
      : theme.colors.background.lightest};
  opacity: ${({ $notSelectable }) => ($notSelectable ? 0.5 : 1)};
  color: ${({ theme }) => theme.colors.text.secondary};
  padding-bottom: ${props => props.theme.spacing(15.5)};
`;
export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: ${props => props.theme.spacing(1, 1)};
`;

export const StyledImage = styled.div`
  border-radius: 16px;
  overflow: hidden;
  object-position: left;
  object-fit: cover;
  height: 82px;
  width: 82px;
  filter: ${props => props.unavailable && 'grayscale(100%) brightness(93%)'};
`;

export const MoreDetails = styled.button`
  position: absolute;
  left: ${props => props.theme.spacing(4)};
  bottom: ${props => props.theme.spacing(8)};
  align-items: center;
  display: flex;
  border: none;
  padding: 0 3px;
  visibility: ${props =>
    props.unavailable || props.disabled ? 'hidden' : 'visible'};
  background-color: transparent;
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.hero.huckleberry};
  text-align: left;
  z-index: 1;

  svg {
    margin-left: ${props => props.theme.spacing(1)};
  }
  &:focus {
    border-radius: ${props => props.theme.spacing(2)};
    outline: 2px solid ${({ theme }) => theme.colors.neutral.darkestFur};
  }
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  padding-left: ${props => props.theme.spacing(2)};
  max-width: 251px;
  width: 100%;
`;

export const Name = styled.h2`
  ${({ theme }) => theme.typography.heading4}
  ${({ theme }) => theme.fontFamily.base}
  text-transform: capitalize;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const StrikeThroughPrice = styled.span`
  ${({ theme }) => theme.typography.body}
  ${({ theme }) => theme.fontFamily.base}
  margin-right: ${({ theme }) => theme.spacing(2)};
  text-decoration-line: line-through;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.status.successPrimary};
`;

export const DisplayedPrice = styled.span`
  ${({ theme }) => theme.typography.bodyBold}
  ${({ theme }) => theme.fontFamily.base}
	margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const CardHeader = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(4)};
`;

export const KeyInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: ${props => props.theme.spacing(2, 4, 0, 0)};
  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

export const PricingSymbol = styled.div``;

export const InactiveSymbol = styled.span`
  color: ${props => props.theme.colors.text.tertiary};
`;

export const ActiveSymbol = styled.span`
  color: ${props => props.theme.colors.text.primary};
`;

export const Description = styled.div`
  ${({ theme }) => theme.typography.label}
  p,
  #text {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  }
  margin-top: ${props => props.theme.spacing(1)};
`;

export const StyledCollapsibleMenu = styled.div`
  max-width: 586px;
  border-radius: 32px;
  background-color: ${props => props.theme.colors.neutral.white};
  padding: ${props => props.theme.spacing(6)};
`;

export const PricesContainer = styled.div``;

export const MenuButton = styled.div`
  max-height: 60px;
  display: flex;
  color: ${props => props.theme.colors.text.secondary};
  justify-content: space-between;
  align-items: center;
  ${props => props.theme.typography.button}
  margin-bottom:${props => props.open && props.theme.spacing(6)};
`;

export const ExpandedMenu = styled.div``;

export const BadgesContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-left: auto;
`;

export const CalloutBadgeContainer = styled.div``;
